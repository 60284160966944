/* App.css */

/* Resets */
body, h1, h2, h3, p, div, section {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* General styles */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  background-color: #f4f4f4;
}

.container {
  padding: 2rem;
}

/* Navigation styles */
.navbar {
  background-color: #333;
  z-index: 1000;
  padding-bottom: 10px;
}

.navbar.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.navbar-brand, .nav-link {
  color: #fff !important;
}

.navbar-brand:hover, .nav-link:hover {
  color: #ccc !important;
}

.navbar-toggler {
  border-color: #fff;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.nav-link {
  margin-left: 1rem;
  padding: 0.5rem 0;
  border-bottom: 3px solid transparent;
  transition: border-color 0.3s;
}

.nav-link.active, .nav-link:focus, .nav-link:hover {
  border-bottom: 3px solid #fed136; /* Highlight color */
}

/* Ensures the logo is on the right */
.navbar-collapse {
  flex-grow: 0;
}

/* Styles specifically for the logo image */
.navbar-brand > img {
  width: 60px; /* Adjust the width as needed */
  height: auto; /* This will maintain the aspect ratio */
  margin-top: 3px;
}
/* You might want to adjust the breakpoints for responsiveness */
@media (max-width: 991px) {
  .navbar-collapse {
    justify-content: space-between;
  }
  .nav-logo {
    margin-left: 0; /* Removes the margin when the navbar is collapsed */
  }
}

/* Main video container */
.video-container {
  margin: 2rem 0;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}

.video-container iframe {
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  height: 100%;
}

/* Text sections */
section {
  margin-bottom: 1rem;
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

section h2 {
  margin-bottom: 0.5rem;
  color: #333;
}

section p {
  color: #666;
}

/* Footer styles */
footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
}

footer a {
  color: #fff;
  text-decoration: none;
  padding: 0 0.5rem;
}

footer a:hover {
  color: #fed136;
}

@media (min-width: 768px) {
  .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 2rem;
  }

  .footer a {
      padding: 0;
  }
  
  .navbar-nav .nav-link {
      margin-left: 0;
      padding: 0.8rem 1rem;
  }
}
